:root {
  --carouselIndicatorWidth: 30px;
  --carouselIndicatorHeight: 3px;

  letter-spacing: 0.1em;
}

.container-wide {
  width: 100%;
  padding-left: 5vw;
  padding-right: 5vw;
}

.px-wide,
.ps-wide {
  padding-left: 5vw;
}

.px-wide,
.pe-wide {
  padding-right: 5vw;
}

.py-wide,
.pt-wide {
  padding-top: 5vw;
}

.py-wide,
.pb-wide {
  padding-bottom: 5vw;
}

.bg-transparent {
  --bs-bg-opacity: 0;
}

.img-fit {
  object-fit: cover;
}

.img-contain {
  object-fit: contain;
}

.pt-wide-download {
  padding-top: calc(5vw - 70px);
}

#download_button a:hover img {
  filter: invert(1);
}

.square-indicators {
  --carouselIndicatorHeight: var(--carouselIndicatorWidth);

  padding-bottom: calc(3 * $carousel-indicator-height);
}

#bg-slider {
  .carousel-indicators {
    button {
      border-color: #eee;
    }
  }
}

.carousel-multi {
  --multiCols: 4;

  &.carousel-multi-cols-4 {
    --multiCols: 4;
  }

  &.carousel-multi-cols-3 {
    --multiCols: 3;
  }

  &.carousel-multi-cols-2 {
    --multiCols: 2;
  }

  @media (max-width: 767px) {
    .carousel-inner .carousel-item > div {
      display: none;
    }

    .carousel-inner .carousel-item > div:first-child {
      display: block;
    }
  }

  .carousel-inner .carousel-item.active,
  .carousel-inner .carousel-item-next,
  .carousel-inner .carousel-item-prev {
    display: flex;
  }

  @media (min-width: 768px) {
    .carousel-inner .carousel-item-end.active,
    .carousel-inner .carousel-item-next {
      transform: translateX(calc(100% / var(--multiCols)));
    }

    .carousel-inner .carousel-item-start.active,
    .carousel-inner .carousel-item-prev {
      transform: translateX(calc(-100% / var(--multiCols)));
    }
  }

  .carousel-inner .carousel-item-end,
  .carousel-inner .carousel-item-start {
    transform: translateX(0);
  }

  &.arrows-below {
    .carousel-inner {
      margin-bottom: 1.5rem;
    }

    .carousel-control-next {
      width: 48%;
      justify-content: start;
      float: right;
      position: relative;
      display: inline-block;
      clear: none;
    }

    .carousel-control-prev {
      justify-content: end;
      width: 48%;
      float: left;
      position: relative;
      display: inline-block;
      clear: none;
    }
  }
}

.dropdown-tabs {
  .nav-holder {
    max-height: 42px;
    flex-wrap: nowrap;
    overflow: hidden;
    border: 1px solid $gray-900;
    background-color: $white;
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 250px;

    .nav {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      height: 42px;
      transition: height 2s ease-in-out 50ms;
      overflow: hidden;

      .nav-item {
        order: 2;
        background-color: $white;
        height: 40px;

        &.active {
          order: 1;

          &:hover {
            background-color: $white;
          }
        }

        &:hover {
          background-color: $light;
        }
      }
    }

    .nav-arrow {
      position: absolute;
      right: 5px;
      top: 0;
      width: 25px;
      height: 40px;
      cursor: pointer;

      .nav-arrow-inner {
        position: relative;
        width: 100%;
        height: 100%;
        display: block;

        .nav-arrow-line {
          width: 60%;
          height: 1px;
          background-color: $gray-900;
          position: absolute;
          top: 50%;
          transition: opacity 1s ease-in;

          &:first-child {
            left: 0;
            transform: translateY(-50%) rotate(45deg);
          }

          &:last-child {
            right: 0;
            transform: translateY(-50%) rotate(-45deg);
          }
        }
      }
    }

    &:hover {
      overflow: visible;
      border-width: 0;

      .nav-arrow {
        .nav-arrow-inner {
          .nav-arrow-line {
            &:first-child {
              transform: translateY(-50%) rotate(-45deg);
            }

            &:last-child {
              transform: translateY(-50%) rotate(45deg);
            }
          }
        }
      }

      .nav {
        height: auto;
        border: 1px solid $gray-900;

        .nav-item {
          border: 1px solid $gray-400;
          height: 40px;
        }
      }
    }
  }
}

.top-header {
  padding: 10px 0;
}

#title-row {
  top: 110px;
  min-height: 85px;

  .menu-toggle {
    transition: opacity 1s ease;

    &:hover {
      opacity: 0.5;
      background-color: transparent;
    }
  }
}

#collapseWidthMenu {
  top: 195px;
  height: calc(100vh - 195px);
  transition: width 1s ease-in-out;

  .nav {
    &.list-group {
      opacity: 1;
      transition: opacity 50ms ease;
    }
  }

  &.close-menu {
    width: 0 !important;

    .nav {
      &.list-group {
        opacity: 0 !important;
      }
    }
  }

  a.list-group-item {
    padding: 1rem 1.25rem;

    & > span {
      width: fit-content;
      border-bottom: 2px solid transparent;
      padding-bottom: 5px;
      display: inline-block;
      margin-bottom: 5px;
    }

    &:hover,
    &.current-page {
      & > span {
        border-bottom-color: $white;
      }
    }
  }

  div.list-group-item {
    .accordion-button {
      & > span {
        width: fit-content;
        border-bottom: 2px solid transparent;
        padding-bottom: 5px;
        display: inline-block;
        margin-bottom: 5px;
      }
    }

    &:hover,
    &.current-page {
      .accordion-button {
        & > span {
          border-bottom-color: $white;
        }
      }
    }
  }
}

.bg-dark .accordion-button::after {
  filter: invert(1) grayscale(1);
}

.dark-hover-holder {
  .dark-hover {
    --bs-bg-opacity: 0.85;
    --bs-dark-rgb: 33, 37, 41;

    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
    opacity: 0;
    transition: opacity 1s ease;
  }

  &:hover {
    .dark-hover {
      opacity: 1;
    }
  }
}

.image-links {
  a {
    filter: invert(1);
    transition: opacity 1s ease;

    &:hover {
      opacity: 0.5;
    }
  }
}

.swatch {
  --bs-aspect-ratio: 100%;

  border-radius: 0;

  .swatch-shape-square & {
    //Square Swatches
  }

  .swatch-shape-circle & {
    //Circle Swatches
    border-radius: 50%;
  }

  .swatch-shape-landscape & {
    //landscape swatches
    --bs-aspect-ratio: calc(3 / 4 * 100%);
  }

  .swatch-shape-portrait & {
    //portrait swatches
    --bs-aspect-ratio: calc(3 / 2 * 100%);
  }

  .swatch-label {
    opacity: 0;
    transition: 1s opacity ease;
  }

  &:hover {
    .swatch-label {
      opacity: 1;
    }
  }
}

.copy-btn {
  .copyIcon {
    position: relative;
    width: 23px;
    height: 30px;

    &::before,
    &::after {
      content: "";
      width: 80%;
      height: 80%;
      border: 2px solid $white;
      position: absolute;
    }

    &::before {
      top: 0;
      left: 0;
    }

    &::after {
      bottom: 0;
      right: 0;
      background-color: $white;
    }
  }

  &.copy-active {
    position: relative;

    .copyText,
    .copyIcon {
      opacity: 0;
    }

    &::after {
      content: "Copied!";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
    }
  }
}

.block-style-image {
  .block-style-image_overlay {
    opacity: 0.4;
    transition: opacity 1s ease;
  }

  .block-style-image_btn {
    visibility: hidden;
    transition: visibility 1s ease-in-out;
  }

  &:hover {
    .block-style-image_overlay {
      opacity: 0.85;
    }

    .block-style-image_btn {
      visibility: visible;
    }
  }
}

.block-style-logo {
  .page_block-logo_content,
  .page_block-logo_back {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  &:hover {
    .page_block-logo_content,
    .page_block-logo_back {
      opacity: 1;
    }
  }
}

.moving-text-wrap {
  .text-holder {
    & > * {
      animation-duration: 4s;
      animation-iteration-count: infinite;
    }

    &.animationStyle-letter-spacing > * {
      animation-name: letterSpacingText;
    }

    &.animationStyle-weight > * {
      animation-name: weightText;
    }

    &.animationStyle-size > * {
      animation-name: sizeText;
    }

    &.animationStyle-line-height > * {
      animation-name: lineHeightText;
    }
  }
}

@keyframes letterSpacingText {
  0% { letter-spacing: normal; }
  50% { letter-spacing: 0.5em; }
  100% { letter-spacing: normal; }
}

@keyframes weightText {
  0% { font-weight: lighter; }
  25% { font-weight: normal; }
  50% { font-weight: bold; }
  75% { font-weight: normal; }
  100% { font-weight: lighter; }
}

@keyframes sizeText {
  0% { font-size: 0.6em; }
  50% { font-size: 1.5em; }
  100% { font-size: 0.6em; }
}

@keyframes lineHeightText {
  0% { line-height: 1em; }
  50% { line-height: 2em; }
  100% { line-height: 1em; }
}

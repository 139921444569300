.top-header {
  .brand {
    max-height: 90px;
  }
}

.menu-toggle {
  width: 35px;
  height: 35px;
  padding: 5px 0;

  .nav-line {
    height: 1px;
  }

  &.close {
    transform: rotate(45deg);

    .nav-line {
      &:nth-child(2) {
        transform: rotate(0deg);
      }

      &:first-child {
        transform: rotate(90deg) translateX(35%);
      }

      &:last-child {
        opacity: 0;
      }
    }
  }
}
